import { SchoolClassInterface } from "../models/SchoolClassInterface"

class LocalStorage {

    static LOCAL_STORAGE_SCHEDULES = "schedules"
    static IS_AUTHENTICATED_KEY = "authenticated"

    static addToLocalStorage (key: string , item: Object) {
        localStorage.setItem(key,JSON.stringify(item))
    }

    static getFromlocalStorage(key: string): string | null {
        return localStorage.getItem(key)
    }

    //TODO remove this method and only keep the one below
    static removeStudyClass(key: string = this.LOCAL_STORAGE_SCHEDULES, position: number) {
        let storedSchedules = JSON.parse(localStorage.getItem(key))

        storedSchedules.splice(position, 1)
        this.addToLocalStorage(this.LOCAL_STORAGE_SCHEDULES, storedSchedules)
        console.log(`Items after deletion ${storedSchedules}`)
        return storedSchedules
    }

    static deleteStudyClass(position: number): SchoolClassInterface {
        let storedSchedules = JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_SCHEDULES))
        let scheduleToRemove = storedSchedules[position]

        console.log(`Schedule to remove ${JSON.stringify(scheduleToRemove)}`)
        LocalStorage.removeStudyClass(this.LOCAL_STORAGE_SCHEDULES,position)
        return scheduleToRemove
    }

    /**
     * mostly useless wrapper but it's good to have everything related to the Localstorage in the same class
     * @param key  the localstorage key that needs to be removed
     */
    static removeItem(key: string) {
        localStorage.removeItem(key)
    }

}


export default LocalStorage;