import { Component, ReactNode } from "react";
import style from "./navmenu.module.css";

class NavMenuItem extends Component<{ title: string, onItemClick: (event) => void }, {}> {

    constructor(props) {
        super(props)
    }

    render(): ReactNode {
        return (
            <p  id={this.props.title}
                className={style.navmenuItem}
                onClick={event => this.props.onItemClick(event)}>
                {this.props.title}
            </p>
        )
    }

}


export default NavMenuItem;