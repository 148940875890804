import styles from "./schoolclass.module.css";

const SchoolClass = (props) => {

    const deleteIconFont = "fa-regular fa-trash"

    const onDeleteHandler = () => {
        console.log("clicked delete")
        if (props.onDelete) {
            props.onDelete()
        }
    }

    return (
        <div className={styles.container}>
            <p  className={styles.schoolClassName}>{props.name}</p>
            <i  className={`${deleteIconFont} ${styles.hideIcon}`}
                onClick={onDeleteHandler}/>
        </div>
    )
}

export default SchoolClass;