const isAuthenticatedSessionStorageKey = "authenticated"

const useProdApi: boolean = true
const showLogs: boolean = false;

const endpoints = {
    authentication : {
        baseUrl: "http://127.0.0.1:5001/fir-cool-a37be/us-central1/",
        prodBaseUrl: "https://us-central1-fir-cool-a37be.cloudfunctions.net/",
        authenticationResource: "authenticate",
        authenticateByTokenResource: "authenticateByToken"
    }
}

export {isAuthenticatedSessionStorageKey, endpoints, useProdApi, showLogs};