// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {initializeAppCheck, ReCaptchaV3Provider} from "firebase/app-check";
import { getFirestore, collection, getDocs, setDoc, doc, addDoc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore"
import { classConverter, SchoolClassInterface, SchoolClassModel } from "../models/SchoolClassInterface";
import Logger from "../utils/Logger";
import LocalStorage from "./LocalStorage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


class FirebaseDb {

    private static db
    private static app
    private static appCheck

    // Your web app's Firebase configuration
    private static firebaseConfig = {
        apiKey: "AIzaSyBlmhwo5GKKYrSgd-lidEOOAFO3lyPOaN0",
        authDomain: "fir-cool-a37be.firebaseapp.com",
        databaseURL: "https://fir-cool-a37be-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "fir-cool-a37be",
        storageBucket: "fir-cool-a37be.appspot.com",
        messagingSenderId: "257411269661",
        appId: "1:257411269661:web:edca6b4471f385297d72f4"
    };

  static init() {
    // Initialize Firebase
    this.app = initializeApp(this.firebaseConfig);
    this.appCheck = initializeAppCheck(this.app, {
      provider: new ReCaptchaV3Provider("6LcvatAjAAAAAFkUTk7O7bfEj8moP3AtTHQSu4VH"),
      isTokenAutoRefreshEnabled: true
    })
  }

  static getDb() {
    return getFirestore(this.app)
  }

  /**
   * 
   * @returns retrieves schedules from the Firestore collection
   */
  static async getSchedules(){//}: Promise<Array<SchoolClassInterface>> {
    let schedulesCollection = collection(this.getDb(), "schedules")
    let parsedSchedules

    try {
      let victorsSchedule = await getDocs(schedulesCollection)

      let unpackedData = victorsSchedule.docs.map(doc => {
        console.debug(`Document id is ${doc.id}`)
        return doc.data()}
      )
      console.log(JSON.stringify(unpackedData))

      let schedules = unpackedData[0]
      console.log(`Received ${JSON.stringify(schedules)}`)
      parsedSchedules = schedules.classes
      
      //adds the retrieved schedules to localstorage for easier access
      LocalStorage.addToLocalStorage(LocalStorage.LOCAL_STORAGE_SCHEDULES, parsedSchedules)
      
      return Promise.resolve(true)
    } catch(err) {
      console.log(`Error while retrieving shedules`)
      return Promise.resolve(false)
    }
  
    //return parsedSchedules.classes
  }

  /**
   * adds a class to the Firebase collection
   * @param classToAdd 
   * @param user 
   * @param collection 
   * @returns 
   */
  static async addClass(classToAdd: Partial<SchoolClassInterface>, 
                        user: string = "victor", 
                        collectionToAddInto: string = "schedules") {

    let data = JSON.stringify(classToAdd)
    console.log(`${JSON.stringify(classToAdd)}`)

    let documentRef = doc(this.getDb(), "schedules", "victor")

    //return await setDoc(documentRef,new SchoolClassModel(classToAdd.name,classToAdd.day,classToAdd.type ?? "normal" ), {merge: true})
    return await updateDoc(documentRef,{classes: arrayUnion({name: classToAdd.name, day: classToAdd.day, type: classToAdd.type ?? "normal"})} )

  }

  /**
   * updates classes in Firestore by removing just the deleted item from the doc
   * can be used in case of deletion oof certain school classes
   */
  static async deleteClasses(classes? : Array<SchoolClassInterface>) {
    let documentRef = doc(this.getDb(), "schedules", "victor")
    return await updateDoc(documentRef,{classes: arrayRemove(classes[0])})
  }
}



export default FirebaseDb;