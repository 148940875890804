import { SchoolClassInterface } from './../models/SchoolClassInterface';
import { endpoints, useProdApi } from "../constants/Constants"
import { hasUncaughtExceptionCaptureCallback } from 'process';
import { AuthResponse } from '../models/AuthResponse';
import Logger from '../utils/Logger';

class ApiService {

    //TODO add from config
    private static baseUrl: string = "http://localhost:8999"
     //routes
    private static scheduleRoute: string = "/schedules"   

    static addClass(withDetails: Partial<SchoolClassInterface>): Promise<number> {

        const endpoint: string = `${this.baseUrl}${this.scheduleRoute}`
        console.log(`Sending ${JSON.stringify(withDetails)}`)
        let responseCode 
        return fetch(endpoint,{
            body: JSON.stringify(withDetails),
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(response => {
            console.log(response)
            return response.text()
        }).then(jsonResponse => {
            responseCode = JSON.parse(jsonResponse).code
            console.log(`Received response ${JSON.stringify(jsonResponse)}`)
            return responseCode
        }).catch (err => {
            return err
        })
    }

    static getClasses(): Promise<Array<Partial<SchoolClassInterface>>> {
        const endpoint: string = `${this.baseUrl}${this.scheduleRoute}`
        
        return fetch(endpoint, {
            method: "GET"
        }).then(response => {
            return response.text()
        }).then (parsedResponse => {
            console.log(`Response is ${parsedResponse}`)
            console.log(`Response 2 is ${JSON.parse(parsedResponse)[0].name}`)
            return JSON.parse(parsedResponse)
        })
    }

    static async authenticate(withEmail: string, withPassword: string): Promise<AuthResponse> {

        let baseUrl = useProdApi ? endpoints.authentication.prodBaseUrl : endpoints.authentication.baseUrl
        let endpoint = `${baseUrl}${endpoints.authentication.authenticationResource}`
        let payload: Object

        if (withEmail && withPassword){
            payload = {email: withEmail, password: withPassword}
        } else {
            throw new Error("User email and password must be NOT Null and NOT Empty")
        }

        return fetch(endpoint, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
              "Content-Type": "application/json"
            }
        }).then(response => {
            return response.json()
        }).then (json => {
            return json
        })
    }

    /**
     * autenticates the user based on a token which exists in the session storage
     * @param token from the session storage
     * @returns 
     */
    static async authenticateWithToken(token: string): Promise<boolean> {

        let baseUrl = useProdApi ? endpoints.authentication.prodBaseUrl : endpoints.authentication.baseUrl
        let endpoint = `${baseUrl}${endpoints.authentication.authenticateByTokenResource}`

        return fetch(endpoint, {
            method: "POST",
            body: JSON.stringify({tokenId: token}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
                return response.json()
            })
            .then(json => {
                let response = json.tokenValid
                return json.tokenValid
            })
    }

}

export default ApiService