import style from "./day.module.css"

const DayContainer = (props) => {

    let classes = `${style.dayContainerParent} ${props.className}`

    return (
        <div className={classes}>
            {props.children}
        </div>
    )
}


export default DayContainer