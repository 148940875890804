import { Component } from "react";
import { Redirect } from "react-router-dom";
import style from "./navmenu.module.css";
import NavMenuItem from "./NavMenuItem";

class NavMenu extends Component {

    menuItems = ["Orar", "Note", "Evenimente", "Concursuri"]

    constructor(props) {
        super(props)
    }

    onMenuItemClick(event) {
        let eventId = event.target.id
        console.log(this.props)
        console.log(`Item clicked ${eventId}`)
        if (eventId === "") {
            <Redirect to=""/>
        }
    }

    clickHandler(event) {
        let eventId = event.target.id
        console.log(`Clicked ${eventId}`)
    }

    render(){
        return <div className={style.navmenu}>
            <ul className={style.navigationItems}>
                {this.menuItems.map(item => {
                    return <NavMenuItem 
                                key={item}
                                title={item} 
                                onItemClick={this.clickHandler}/>
                })}
            </ul>
        </div>
    }

}



export default NavMenu;