import Card from "../card/Card";
import {Redirect, useHistory} from "react-router-dom";
import styles from "./login.module.css";
import Button from "../button/Button";
import { useEffect, useRef, useState } from "react";
import {isAuthenticatedSessionStorageKey} from "../../constants/Constants"
import ApiService from "../../networking/ApiService";
import Navigator from "../../routes/Navigator";

const Login = () => {

    let userNameRef = useRef<HTMLInputElement>()
    let passwordRef = useRef<HTMLInputElement>()

    const history = useHistory()
    const [userName, setUserName] = useState("Enter your username ...")
    const [password, setPassword] = useState("")
    const [authenticated, setIsAuthenticated] = useState(false)
    const [isFormValid, setFormValid] = useState(true)

    const onFocusHandler = (event) => {
        let elementValue = event.target.value
        setFormValid(false)
    }

    const setAuthenticated = (authTokenId: string) => {
        localStorage.setItem(isAuthenticatedSessionStorageKey,authTokenId)
    }

    const onClickHandler = async (event) => {
        console.log(`Clicked ${event.target.id}`)
        event.preventDefault()

        let uname = userNameRef.current.value
        let pwd = passwordRef.current.value
        let authResponse
        
        try {
            authResponse = await ApiService.authenticate(uname, pwd)
            console.log(`Received the auth response ${JSON.stringify(authResponse)}`)

            if (authResponse.code === 200) {
                let {code, message, tokenId}  = authResponse
                setAuthenticated(tokenId)
                //Navigator.getInstance().goToDashboard()
                history.push("/dashboard")
            }
        }catch(error) {
            alert("Not authorized")
        }

        // if (authResponse.code === 200) {
        //     let {code, message, tokenId}  = authResponse
        //     setAuthenticated(tokenId)
        //     //Navigator.getInstance().goToDashboard()
        //     history.push("/dashboard")
        // } else {
        //     console.log("Not authorized")
        //     alert(authResponse.message)
        // }
    }

    return (
       
        <div className={styles.parent}>
            <div className={styles.left_side}>
                <h1>Welcome to Skewl</h1>
                <h2>Your digital school agenda and scheduler</h2>
            </div>
            <div className={styles.right_side}>

                <div className={styles.title_responsive}>
                    <h1>Welcome to Skewl</h1>
                    <h2>Your digital school agenda and scheduler</h2>
                </div>

                <Card className={styles.form_card}>
                    <form onSubmit={(event) => {
                        event?.preventDefault()
                        console.log("Submitted")
                    }}>
                        <label htmlFor="username">Username</label>
                        <input id="username" 
                                type="email" 
                                className={!isFormValid ? `${styles.invalid}` : ``}
                                onBlur={onFocusHandler}
                                // onClick={onFocusHandler}
                                ref={userNameRef}/>
                        <label htmlFor="password">Password</label>
                        <input id="password" 
                                type="password"
                                onBlur={onFocusHandler}
                                className={!isFormValid ? `${styles.invalid}` : ``}
                                ref={passwordRef}/>
                        <Button title="Login" clickHandler={onClickHandler}/>
                    </form>
                </Card>
            </div>
        </div>
    )

};


export default Login;