import { Component, ReactNode } from "react";
import styles from "./toolbarstyles.module.css";


export interface ToolbarProps {
    title: string,
    hasLogout: boolean,
    logoutHandler: () => void
}

class Toolbar extends Component<ToolbarProps,{}> {

    classes: string

    static defaultProps = {
        title: "Scool",
        hasLogout: true,
        logoutHandler: () => {console.log('Default , unspecified action')}
    }

    constructor(props) {
        super(props);
        this.classes = props.classes ? `${props.classes} ${styles.toolbar}` : `${styles.toolbar}`
    }

    render(): ReactNode {
        return (<div className={this.classes}>
                <p className={styles.toolbarTitle}>{this.props.title}</p>
                <button className={styles.logout} onClick={this.props.logoutHandler}>Logout</button>
        </div>)
    }

}


export default Toolbar;