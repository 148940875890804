import React from 'react';
import './App.css';
import Login from './components/login/Login';
import {Route} from "react-router-dom";
import Dashboard from './routes/Dashboard';
import {isAuthenticatedSessionStorageKey} from "./constants/Constants";
import ApiService from './networking/ApiService';

function App() {

  
  let getIsAuthenticated = () => {

    let sessionToken = localStorage.getItem(isAuthenticatedSessionStorageKey)
    if (sessionToken) {
      return ApiService.authenticateWithToken(sessionToken)
              .then(isValid => {
                return isValid
              })
              .catch(error => console.log(`Error when checking if logged in ${error}`))
    } else {
      return false
    }
  }

  return (
    <div className="App">    
      <Route path="/login">
        <Login/>
      </Route>
      <Route path="/dashboard">
        <Dashboard/>
      </Route>
      <Route path="/">
        {(getIsAuthenticated()) ? <Dashboard/> : <Login/>}
      </Route>
    </div>
  );
}

export default App;
