import style from "./dashboard.module.css";
import Day from "../components/dayCard/Day";
import Modal from "../components/modal/Modal";
import { useState, Component } from "react";
import { SchoolClassInterface } from "../models/SchoolClassInterface";
import Button from "../components/button/Button";
import ApiService from "../networking/ApiService";
import ModalHolder from "../components/modal/Modal";
import FirebaseDb from "../database/FirebaseDb";
import Toolbar from "../components/toolbar/Toolbar";
import NavMenu from "../components/navmenu/NavMenu";
import { Redirect, withRouter } from "react-router-dom";
import { isAuthenticatedSessionStorageKey } from "../constants/Constants";
import LocalStorage from "../database/LocalStorage";


class Dashboard extends Component<{history}, { 
    showModal: boolean,
    classes: Array<Partial<SchoolClassInterface>>,
    isAuthenticated: boolean}> {

    classesArray: Array<Partial<SchoolClassInterface>> = []

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            classes: [],
            isAuthenticated: false
        }

        //this.deleteItemHandler.bind(this)
    }

    async componentDidMount() {
        //this.refreshDataFromBackend()
 
        if (!await this.getIsAuthenticated()) {
            this.props.history.push("/login")
            return
        }
        
        FirebaseDb.init()
        this.getDataFromFirebase()
        
    }

    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{ showModal: boolean; classes: Array<Partial<SchoolClassInterface>>; }>, snapshot?: any): void {
 
    }

    onBackdropClicked(): void {
        console.log("Clicked backdrop")
        this.getDataFromFirebase()
        this.setState({showModal: false})
    }

    newItemHandler(): void {
        console.log("Clicked new Item")
        this.setState({showModal: true})
    }

    logoutHandler = () => {

        LocalStorage.removeItem(LocalStorage.IS_AUTHENTICATED_KEY)
        this.setState({isAuthenticated: false})
        this.props.history.push("/login")
    }

    deleteItemHandler = (itemId) => {
        console.log('Delete handler from Dashboard')
        console.log(`Clicked item ${itemId}`)

        //remove from cache
        let scheduleToRemove = LocalStorage.deleteStudyClass(itemId)
        console.log(`Schedule to remove is ${JSON.stringify(scheduleToRemove)}`)

        //remove from db
        FirebaseDb.deleteClasses([scheduleToRemove])
       
        //get the new classes from local storage and update the state 
        let schedules = LocalStorage.getFromlocalStorage(LocalStorage.LOCAL_STORAGE_SCHEDULES)
        console.log(`schedules from local storage after deletion \n ${schedules}`)
        
        //state update
        this.setState({classes: JSON.parse(schedules)})  
        
    }

    /**
     * method to determine whether the user is authenticated
     * @returns 
     */
    private async getIsAuthenticated(): Promise<boolean> {

        let token = localStorage.getItem(isAuthenticatedSessionStorageKey)
        if (!token) {
            this.setState({isAuthenticated: false})
            return false
        } else {
            let tokenIsValid = await ApiService.authenticateWithToken(token)
            this.setState({isAuthenticated: true})
            return tokenIsValid
        }
    }

    getDayItems = (forDay: string): SchoolClassInterface[] => {

        let classesForDay: Array<SchoolClassInterface> = []

        this.state.classes.forEach(item => {
            //console.log(`Classes item ${item.name}`)
            if (item.day.toLowerCase() === forDay) {
                let id = this.state.classes.indexOf(item)
                let {name, day, type} = item
                classesForDay.push({id, name, day, type})
            }

        })
        return classesForDay
    }

    render() {
        return (
        <div className={style.main}>
            <Toolbar title="S-Kewl" logoutHandler={this.logoutHandler}/>
            <div className={style.content}>

                <NavMenu/>
                <div className={style.dashboardParent}>
                
                    { (this.state.showModal) ? <ModalHolder 
                                        onBackdropClick={this.onBackdropClicked.bind(this)} 
                                        classesToStore={this.state.classes}/> : null}
                    <Day title="Monday" 
                        clickHandler={this.newItemHandler.bind(this)} 
                        classesToAttend={this.getDayItems("monday")}
                        onDeleteHandler={this.deleteItemHandler}
                        />
                    <Day title="Tuesday" 
                        classesToAttend={this.getDayItems("tuesday")}
                        onDeleteHandler={this.deleteItemHandler} />
                    <Day title="Wednesday" 
                        classesToAttend={this.getDayItems("wednesday")}
                        onDeleteHandler={this.deleteItemHandler} />
                    <Day title="Thursday" 
                        classesToAttend={this.getDayItems("thursday")}
                        onDeleteHandler={this.deleteItemHandler}/>
                    <Day title="Friday" 
                        classesToAttend={this.getDayItems("friday")}
                        onDeleteHandler={this.deleteItemHandler} />
                    
                    <Button clickHandler={this.newItemHandler.bind(this)} title="+" className={style.addButton}/>
                </div> 

            </div>
            
        </div>
        )
    }

    /**
     * used to retrieve new data from the backend after items are added
     */
    private refreshDataFromBackend() {
        ApiService.getClasses().then(
            res => {
                this.setState({classes: res})
                res.forEach(item => {
                    console.log(`Retrieved classes ${item.name} - ${item.day}`)
                })
            }
        )
    }

    private getDataFromFirebase() {
        FirebaseDb.getSchedules()
        // .then( items => {
        //     console.log(`Dashboard ${items[0].day}`)
        //     console.log(JSON.stringify(items))

        //     this.setState(
        //         {
        //             classes: JSON.parse(JSON.stringify(items))
        //         }
        //     )
        //     }
        // )
        .then(hasRetrievedData => {
            if (hasRetrievedData) {
                let schedules = LocalStorage.getFromlocalStorage(LocalStorage.LOCAL_STORAGE_SCHEDULES)
                console.log(`schedules from local storage \n ${schedules}`)
                         
                this.setState ({classes: JSON.parse(schedules)})    
                console.log(`dashboard classes ${this.state.classes}`)
            }
        })
        .catch (err => { 
            console.log(err)
            alert("Error when retrieving the schedule")
        })
    }

}


//export default Dashboard;
export default withRouter(Dashboard);