import styles from "./modal.module.css";
import React,{ useState } from "react";
import ReactDOM from "react-dom";
import Card from "../card/Card";
import Button from "../button/Button";
import ApiService from "../../networking/ApiService";
import { SchoolClassInterface } from "../../models/SchoolClassInterface";
import { ClassType } from "../../models/ClassTypeEnum";
import FirebaseDb from "../../database/FirebaseDb";


const BackdropComponent = (props) => {
    return (
        <div id="backdrop" className={styles.backdrop} onClick={props.backdropClickHandler}>
            {props.children}
        </div>
    )
}

const ModalHolder = (props) => {

    return (
        <React.Fragment>
            {ReactDOM.createPortal(<BackdropComponent backdropClickHandler={props.onBackdropClick}/>, document.getElementById("backdrop"))}
            {ReactDOM.createPortal(<Modal onBackdropClick={props.onBackdropClick} classesToStore={props.classesToStore}/>, document.getElementById("errorModal"))}
        </React.Fragment>
    )
}

const Modal = (props) => {

    const [matterName, setMatterName] = useState("")
    const [matterDay, setMatterDay] = useState("")
    const [matterType, setMatterType] = useState("")

    const backdropClickHandler = (event = null) => {
        if (event == null || event?.target.id == "backdrop") {
            console.log("backdrop clicked")
            props.onBackdropClick(true)
        }

        if (event?.target.id != "backdrop") {
            event?.preventDefault()
        }
    }

    const inputChangeHandler = (event) => {
        event.preventDefault()
        let value = event.target.value
        console.log(value)

        setMatterName(value)
    }

    const selectHandler = (event) => {
        //let value = event.target.selectedIndex
        let value = event.target.value
        let eventId = event.target.id
        console.log(value)

        if (eventId === "classDay") {
            console.log(`Selected class day ${value}`)
            setMatterDay(value)
        } else {
            console.log(`Selected class type ${value}`)
            setMatterType(value)
        }
    }


    //[{"classes":[{"type":"normal","day":"Monday","name":"CLR"}]}]
    const formSubmitHandler = (event) => {
        console.log("Add matter button clicked")
        event.preventDefault()
        if (matterName && matterDay && !matterDay.startsWith("Select")) {
            console.debug("We have all the data")

            let payload: Partial<SchoolClassInterface> = { name: matterName, day: matterDay, type: ClassType[matterType] }

            //let enhancedPayload = [...props.classesToStore, payload]

           // console.log(`Payload data ${enhancedPayload}`)

            FirebaseDb.addClass(payload)
                        .then(response => {
                            console.log(`${response}`)
                            backdropClickHandler()
                        })
                        .catch(err => {
                            console.log(`Error occured ${err}`)
                        })

            /*ApiService.addClass(payload).then(res => {
                console.debug(`Finalized with ${res}`)
                if (res === 200) {
                    backdropClickHandler()
                } else {
                    console.log("No backend connectivity")
                    return alert("No backend connectivity")
                }
            })*/


        }
    }

    return (
        
            <Card>
                <div className={styles.modal}>
                    <form id="matterForm">
                        <label htmlFor="classMatterName">Materia</label>
                        <input id="classMatterName" value={matterName} onChange={inputChangeHandler} />
                        <label htmlFor="classMatterName">Ziua</label>
                        <select id="classDay" onChange={selectHandler} onSelect={selectHandler}>
                            <option value="">Select class day ... </option>
                            <option value="monday">Monday</option>
                            <option value="tuesday">Tuesday</option>
                            <option value="wednesday">Wednesday</option>
                            <option value="thursday">Thursday</option>
                            <option value="friday">Friday</option>
                        </select>

                        <label htmlFor="classType">Selectati tipul materiei</label>
                        <select id="classType" onChange={selectHandler} onSelect={selectHandler}>
                            <option value="normal">Normal</option>
                            <option value="optional">Optional</option>
                        </select>

                        <Button title="Adauga" clickHandler={formSubmitHandler} />
                    </form>
                </div>
            </Card>
        
    )

}

export default ModalHolder;