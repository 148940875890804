import Button from "../button/Button";
import SchoolClass from "../class/SchoolClass";
import style from "./day.module.css";
import DayContainer from "./DayContainer";
import { SchoolClassInterface } from "../../models/SchoolClassInterface";
import { ClassType } from "../../models/ClassTypeEnum";
import { useEffect, useState } from "react";


const Day = (props) => {

    let title = props.title
    let classesToAttend: Array<SchoolClassInterface> = props.classesToAttend
    let [classes, setClassesToAttend] = useState(classesToAttend)
    
    let clickHandler = (event) => {
        console.log("Clicked add new")
        props.clickHandler()
    }

    let deleteHandler = (itemId) => {
        props.onDeleteHandler(itemId)
    }

    useEffect( () => {
        console.log(`Items ${JSON.stringify(classes)}`)
    },[classes])


    return (
            <DayContainer className={style.item}>
                <h1>{title}</h1>
                <div className={style.divider}></div>

                <div className={style.classesContainer}>
                    {classesToAttend.map((item) => {

                        if (item.type === ClassType.normal || item.type === "") {
                            return <SchoolClass
                                key={Math.random()}
                                name={item.name}
                                onDelete={() => {
                                    //TODO add body
                                    console.log("Clicked function passed from parent")
                                    console.log(`Clicked ${JSON.stringify(item.id)}`)
                                    deleteHandler(item.id)
                                }}/>
                        }
                    })}
                </div>

                <div className={style.optionals}>
                    <div className={style.divider}></div>
                    <p>Optionale</p>
                    <div className={style.classesContainer}>
                        {classesToAttend.map((item) => {

                            if (item.type === ClassType.optional) {
                                return <SchoolClass
                                    key={Math.random()}
                                    name={item.name}
                                    onDelete={() => {
                                        //TODO add body
                                        console.log("Clicked function passed from parent")
                                        console.log(`Clicked ${JSON.stringify(item.id)}`)
                                        deleteHandler(item.id)
                                    }}   />
                            }
                        })}
                    </div>
                </div>

            </DayContainer>
    )

}


export default Day;