import styles from "./button.module.css";

const Button = (props) => {

    const buttonTitle = props.title
    const onClickHandler =  props.clickHandler

    const classes = (props.className) ? props.className : styles.cz_button

    return (<button 
        className={classes}
        onClick={onClickHandler}>
        {buttonTitle}
        </button>)
}


export default Button;